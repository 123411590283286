$dark-blue: #025EA1;
$blue: #1673CA;
$light-blue: #59b0e9;
$green: #0BC154;
$yellow: #FFE500;
$orange: #FF4A05;
$red: #ff4d4f;
$black: #000000;
$gray-1: #A4A4A4;
$gray-2: #C5C5C5;
$gray-3: #D3D3D3;
$gray-4: #E5E5E5;
$gray-5: #F9F9F9;
$gray-6: #646464;
$gray-7: #C4C4C4;
$white: #FFFFFF;