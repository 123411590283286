@import "/src/styles";

.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 2rem 5rem 0 5rem !important;

  &:hover {
    .text {
      color: var(--blue);
    }
  }
}

.logo {
  width: 18.9rem;
  height: 6.8rem;
  border-radius: $border-radius;
  background-image: none;
}

.preview {
  background-color: var(--gray-4);
}

.text {
  color: var(--color-secondary);
  margin-top: $margin-xs;
  font-weight: 300;
  @include font-size-14;

  @media(max-width: $xxl) {
    display: none;
  }
}