@import "/src/styles";

.container {
  display: flex;

  & .library {
    margin-right: 1.5rem !important;
  }
}

.menu {
  border: 1px solid var(--border-color);
  min-width: 18rem;

  & :global(.ant-dropdown-menu-item) {
    padding: 1rem !important;
  }
}

.dropdown {
  &:global(.ant-dropdown-open) {
    background: var(--white);
    border-top: .5rem solid var(--blue);
    color: black;
  }
}

.logo {
  top: -.5rem;
}
