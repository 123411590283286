@import "./constants";

.ant-picker-header-view button {
  color: var(--gray-1);

  &:hover {
    color: var(--blue);
  }
}

.ant-picker-input input {
  font-size: $input-font-size !important;
  line-height: $input-line-height !important;
}

.ant-picker-suffix {
  height: 2.4rem;
  min-width: 2.4rem;
  border-radius: $border-radius / 2;
  background-color: var(--gray-5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-picker-clear {
  height: 2.4rem;
  width: 2.4rem;
  border-radius: $border-radius / 2;
  background-color: var(--gray-5);
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='8px' height='8px' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6564 11.6568C11.2659 12.0473 10.6327 12.0473 10.2422 11.6568L5.99957 7.41416L1.75691 11.6568C1.36639 12.0473 0.733223 12.0473 0.342698 11.6568C-0.0478263 11.2663 -0.0478268 10.6331 0.342698 10.2426L4.58535 5.99994L0.342728 1.75732C-0.0477965 1.36679 -0.0477963 0.733628 0.342728 0.343104C0.733253 -0.0474206 1.36642 -0.0474206 1.75694 0.343103L5.99957 4.58573L10.2422 0.343103C10.6327 -0.0474224 11.2659 -0.0474215 11.6564 0.343102C12.0469 0.733627 12.0469 1.36679 11.6564 1.75732L7.41378 5.99994L11.6564 10.2426C12.047 10.6331 12.047 11.2663 11.6564 11.6568Z' fill='%23333333'/%3E%3C/svg%3E");

  svg {
    display: none;
  }
}

.ant-picker-date-panel .ant-picker-body {
  padding: $padding-xs $padding-md;
}

.ant-picker-today-btn {
  color: var(--dark-blue);
}

