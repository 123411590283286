@import "/src/styles/";

.modal {
  min-width: 57rem !important;
  height: 80vh !important;
  padding: 0;
  top: 0;
}

:global(.ant-modal-wrap) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.ant-modal-content) {
  height: 100%;
  max-height: 90vh;
  padding: 2.8rem;
  padding-bottom: 4rem;
  border-radius: $border-radius;
  overflow-y: auto;
}

:global(.ant-modal-header) {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 3.2rem;
}

:global(.ant-modal-title) {
  @include font-size-20;
}

:global(.ant-modal-body) {
  padding: 0;
}

.loader {
  min-height: 30vh;
  height: 100%;
}