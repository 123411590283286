@import "/src/styles";

.container {
  margin-top: $margin-xs;
}

.link {
  @include font-size-16;
  display: inline-block;

  &-show-content {
    max-width: 20rem;
    margin-right: 3rem !important;
    border-bottom: 1px dashed var(--blue);
    @include ellipsis;

    &:hover {
      border-bottom: 1px dashed var(--light-blue);
    }
  }
}