@import "/src/styles";

.title {
  margin-bottom: $margin-md !important;
}

.container {
  width: 100%;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
