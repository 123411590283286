@import "/src/styles";

$input-bg: var(--white);
$input-br-color: var(--border-color);
$input-br-hover-color: var(--light-blue);
$input-br-active-color: var(--blue);
$input-color: var(--color-primary);
$input-placeholder: var(--gray-1);

$input-bg-disabled: var(--gray-5);
$input-color-disabled: var(--gray-1);

$input-font-size: 1.4rem;
$input-line-height: 2rem;

$horizontal-padding: $padding-md;
$horizontal-margin: $margin-md;

$input-borderless-br-color: var(--blue);
$input-borderless-br-hover-color: var(--light-blue);
$input-borderless-br-active-color: var(--dark-blue);

$horizontal-padding-borderless: $padding-xs;