.form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.control {
  width: 45%;
}

.dash {
  margin-bottom: 3rem;
}