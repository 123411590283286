@import "./constants";

.ant-picker-panel-container {
  border-radius: $border-radius;
}

.ant-picker-panel-container .ant-picker-panel th {
  @include font-size-12;
  width: $cell-width;
  height: $cell-height;
}