@import "./constants.scss";

.app-select.ant-select.ant-select-multiple {
  height: auto !important;
  min-height: $input-line-height;

  .ant-select-selector {
    padding: .5rem !important;
    padding-right: 5.6rem !important;
    padding-left: $horizontal-padding !important;
  }

  .ant-select-selection-search {
    padding: 0 !important;
    margin-left: 0 !important;
    font-size: $input-font-size !important;
    line-height: $input-line-height !important;
    overflow: hidden;

    input {
      font-family: "Roboto", sans-serif !important;
    }
  }

  .ant-select-selection-placeholder {
    padding-left: $horizontal-padding !important;
  }

  .ant-select-clear {
    top: auto !important;
  }

  .ant-select-selection-item {
    display: flex !important;
    padding: 0 .3rem !important;
    border-radius: .5rem;

    .ant-select-selection-item-content {
      margin-right: .6rem !important;
    }
  }
}
