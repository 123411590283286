@import "src/styles";

.container {
  max-width: 3rem;
  align-self: flex-start;

  & > * {
    margin-right: $margin-xxxs !important;
  }
}

.button {
  width: 1rem;
  height: 1rem;
  padding: $padding-sm;
}

.input {
  min-width: 3rem;
  height: 3rem;
  padding: 0;

  & > input {
    text-align: center;
  }
}