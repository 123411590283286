@import "/src/styles";

.app-collapse {
  &.ant-collapse {
    @include box-shadow;
    border: none;
    border-radius: $border-radius;
    background-color: $white;
    padding: $padding-md;

    .ant-collapse-content {
      border-top: none;
    }

    & > .ant-collapse-item {
      border-bottom: none;
    }

    .ant-collapse-extra {
      margin-left: auto;
    }
  }
}

.ant-collapse-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}