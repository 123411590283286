@import "/src/styles";

$offset-bottom: 1.8rem;

.container {
  display: flex;
  margin-bottom: $offset-bottom;
}

.title {
  display: inline-block;
  text-align: right;
  width: 9rem;
  margin-bottom: 0 !important;
  padding-right: $padding-md;
  @include font-size-12;
  color: var(--color-secondary);

  &--stretch {
    width: auto;
  }
}

.content {
  flex: 1;
  position: relative;
  margin-bottom: 0 !important;
  padding-bottom: $offset-bottom !important;
  @include font-size-14;
  color: var(--color-primary);
}

.content::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.1rem;
  background-color: var(--gray-3);
}