@import "/src/styles";

.link {
  @include font-size-16;
  margin-left: $margin-xxs;
  line-height: 2.5rem !important;

  & svg {
    margin-top: 0.1rem;
  }

  &:hover {
    transition: none;

    & svg path {
      fill: var(--light-blue);
    }
  }
}

.count {
  line-height: 2.5rem !important;
}