@import "../constants";

.app-select {
  .ant-select-clear {
    right: 0;
    top: auto !important;
    margin-top: 0;
    margin-right: $icon-margin-right;
    background-color: $input-bg !important;
    height: $icon-edge-size;
    width: $icon-edge-size;
    border-radius: .5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='0.8rem' height='0.8rem' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6564 11.6568C11.2659 12.0473 10.6327 12.0473 10.2422 11.6568L5.99957 7.41416L1.75691 11.6568C1.36639 12.0473 0.733223 12.0473 0.342698 11.6568C-0.0478263 11.2663 -0.0478268 10.6331 0.342698 10.2426L4.58535 5.99994L0.342728 1.75732C-0.0477965 1.36679 -0.0477963 0.733628 0.342728 0.343104C0.733253 -0.0474206 1.36642 -0.0474206 1.75694 0.343103L5.99957 4.58573L10.2422 0.343103C10.6327 -0.0474224 11.2659 -0.0474215 11.6564 0.343102C12.0469 0.733627 12.0469 1.36679 11.6564 1.75732L7.41378 5.99994L11.6564 10.2426C12.047 10.6331 12.047 11.2663 11.6564 11.6568Z' fill='%23333333'/%3E%3C/svg%3E");
    background-size: 1.2rem;

    svg {
      display: none;
    }
  }
}