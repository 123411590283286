@import "../constants";

.app-select {
  .ant-select-selection-placeholder {
    left: 0 !important;
    padding: 0 !important;
    width: 100%;

    font-size: $input-font-size !important;
    line-height: $input-line-height !important;
    color: $input-color !important;
    opacity: 1;
  }
}