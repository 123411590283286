.form-item {
  width: 14.7rem;
}

.input {
  background-color: inherit;

  input {
    background-color: inherit;
  }
}