*::-webkit-scrollbar-track {
  border-radius: 10px;

  background-color: #8191A740;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #8191A799;
}

* {
  scrollbar-color: #8191A799 #8191A740;
  scrollbar-width: thin;
}