@import "/src/styles";

.app-card {
  &.ant-card {
    @include box-shadow;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
  }

  &:hover {
    opacity: .8;
  }

  & .ant-card-body {
    padding: $padding-lg;
    height: 100%;
  }
}
