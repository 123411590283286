@import "./constants.scss";

.ant-checkbox-wrapper {
  align-items: center;
}

.ant-checkbox {
  top: 0;
}

.ant-checkbox-inner {
  width: $checkbox-side-size;
  height: $checkbox-side-size;
  border-color: $checkbox-border-color;
  background-color: transparent;
}

.ant-checkbox-checked {

  & .ant-checkbox-inner {
    background-color: transparent;
  }

  & .ant-checkbox-inner:after {
    border-color: $checkbox-border-color;
    top: 44%;
    left: 18.5%;
    width: $checkbox-after-width;
    height: $checkbox-after-height;
  }

}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: $checkbox-border-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner:after,
.ant-checkbox-checked:after,
.ant-checkbox-inner:hover {
  border-color: $checkbox-border-color-hover;
}

.ant-checkbox-checked .ant-checkbox-inner:after,
.ant-checkbox-inner:after {
  transition: none;
}
