@mixin ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

@mixin multiline-ellipsis($line-count) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $line-count;
  line-clamp: $line-count;
  -webkit-box-orient: vertical;
}

@mixin box-shadow {
  box-shadow: 0 .4rem 1rem rgba(0, 0, 0, .06) !important;
}