.card {
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  width: 32.8rem;
  border-radius: 1rem !important;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem !important;
    height: 1rem !important;

  &:hover {
    opacity: .5;
  }
}