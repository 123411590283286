@import "/src/styles";

.container {
  flex: 1;
  align-items: center;
  background-color: var(--white);
  border-radius: $border-radius;
  @include box-shadow;

  > :last-child {
    margin-right: .2rem !important;
  }
}

.search-input {
  border: none;
}

.search-select {
  flex: 0 0;
  min-width: 19.3rem;
  max-width: 19.3rem;
  height: 4.2rem !important;
  border: none !important;
  background-color: var(--gray-5) !important;
}