@import "/src/styles";
@import "/src/styles/fonts";
@import "/src/styles/variables";
@import "/src/styles/scrollbar";

* {
  font-family: "Roboto", sans-serif;
}

html {
  font-size: 62.5%;

  @media(max-width: $xl) {
    font-size: 50%;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  @include font-size-14;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
}

html, body, #root {
  height: 100%;
  width: 100%;
}