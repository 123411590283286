@import "/src/styles";

.content {
  width: 100%;
}

.container {
  width: 100%;
}

.link {
  width: 30%;
}
