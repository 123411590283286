@import "/src/styles";
@import "../constants";

.ant-select-item-option-content {
  @include font-size-14;
  white-space: normal !important;
}

.ant-select-selection-item {
  @include ellipsis;

  display: inline-block !important;
  padding-right: 0 !important;
  font-size: $input-font-size !important;
  line-height: $input-line-height !important;

  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

.ant-select-item.ant-select-item-option {
  padding-top: $padding-xs;
  padding-bottom: $padding-xs;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--gray-4) !important;
  color: var(--color-primary) !important;
}