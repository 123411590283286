@mixin font-size-30 {
  font-size: 3rem !important;
  line-height: 3.6rem !important;
}

@mixin font-size-20 {
  font-size: 2rem !important;
  line-height: 2.8rem !important;
}

@mixin font-size-18 {
  font-size: 1.8rem !important;
  line-height: 2.4rem !important;
}

@mixin font-size-16 {
  font-size: 1.6rem !important;
  line-height: 2.2rem !important;
}

@mixin font-size-14 {
  font-size: 1.4rem !important;
  line-height: 2rem !important;
}

@mixin font-size-12 {
  font-size: 1.2rem !important;
  line-height: 1.8rem !important;
}