@import "/src/styles";

.card {
  border-radius: 0 .6rem .6rem 0 !important;
  transition: all .5s;
  width: 100%;

  &:hover {
    transform: translateY(-0.3rem);
  }
}

.info-container {
  flex: 1;
}

.image {
  width: 8.7rem;
  height: 12.2rem;
  border: 1px solid var(--gray-3);
}

.title {
  margin-right: .5rem;
  font-weight: 700 !important;
  @include multiline-ellipsis(3);
}

.date {
  display: inline;
}

.author {
  @include multiline-ellipsis(1);
}