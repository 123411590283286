@import "./constants";
@import "./container";
@import "./components";
@import "./cell";

.ant-picker {
  width: 100%;
  font-size: $input-font-size;
  line-height: $input-line-height;
  background-color: $input-bg;
  border-radius: $border-radius;
  border: 1px solid $input-br-color;
  align-items: center;
  height: $ui-element-height;
  padding: $padding-xxs $padding-md !important;
  padding-right: $padding-xxxs !important;

  &:hover {
    border: 1px solid var(--light-blue);
  }

  input::placeholder {
    color: $input-placeholder !important;
  }
}

.ant-picker.ant-picker-focused {
  box-shadow: none;
  border-color: var(--blue);
}