@import "../constants";

.app-select {
  .ant-select-selection-search-input {
    position: relative;

    margin-left: 0 !important;
    padding: 0 !important;
    height: auto !important;
    width: 100%;

    font-size: $input-font-size !important;
    line-height: $input-line-height !important;
  }
}