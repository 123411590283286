@import "/src/styles";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  padding: $padding-xxs;
  padding-left: $padding-sm;
  border-radius: $border-radius;
  border: 1px solid var(--border-color);
  cursor: pointer;

  &:hover {
    border: 1px solid var(--light-blue);
  }

  &:focus-within {
    border: 1px solid var(--blue);
  }
}

.title {
  margin-right: $margin-sm;
}