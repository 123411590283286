.modal {
  width: 960px !important;
  height: 534px !important;
}

.rate {
  font-size: 3rem;
}

.area-container {
  flex-basis: 75%;
}

.submit {
  width: 30%;
}

.textarea {
  min-height: 22rem !important;
}