@import "src/styles";

.reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.rate-reverse {
  margin-left: $margin-xxxs;
}

.rate-count {
  color: var(--gray-1);
}