@import "/src/styles";

.card {
  background-color: transparent;
  padding: 1.4rem;
  padding-left: 0;
  padding-right: 2.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  border-top: .5rem solid var(--gray-5);
  position: relative;
  color: var(--blue);

  &::before {
    content: "";
    height: 9.5rem;
    width: .1rem;
    margin-right: 2.4rem;
    background: var(--border-color);
  }

  & :global(h4.ant-typography) {
    color: var(--blue) !important;
  }

  &:hover {
    background: var(--white);
    border-top: .5rem solid var(--blue);

    .title {
      color: var(--black) !important;
    }
  }
}

.logo {
  position: absolute;
  width: .5rem;
  width: 1rem;
  top: -1rem;
  left: 1rem;
}

.active {
  background: var(--white);
  border-top: .5rem solid var(--blue);

  & :global(h4.ant-typography) {
    color: var(--black) !important;
  }
}
