@import "/src/styles";

.link {
  @include font-size-16;
  display: inline-block;
  margin-top: $margin-xs;
  margin-bottom: $margin-lg;

  & svg {
    margin-top: 0.1rem;
  }

  &:hover {
    transition: none;

    & svg path {
      fill: var(--light-blue);
    }
  }

  &-title {
    border-bottom: 1px dashed var(--blue);
  }
}