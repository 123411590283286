.container {
  padding: 3.7rem;
  padding-bottom: 7.9rem;
  flex: 1;
  cursor: default !important;

  &:hover {
    opacity: 1;
  }
}

.image {
  width: 25.1rem !important;
  height: 36rem !important;
}
