@import "/src/styles";

$image-height: 36rem;
$image-width: 25.1rem;

.card {
  padding: 3.7rem;
  padding-bottom: 7.9rem;
  min-height: 80rem;
  flex: 1;
  cursor: default !important;

  &:hover {
    opacity: 1;
  }
}

.title-container {
  align-items: baseline;
}

.image {
  width: $image-width;
  height: $image-height;
  border: 1px solid var(--border-color);
}

.rating {
  align-self: center;
}

.title {
  margin-bottom: 4rem !important;
}

.button-container {
  margin-left: 9rem;
  margin-top: $margin-md;
}

.info {
  flex: 1;
}

.video {
  width: auto;
  margin-left: 9rem;
  height: 40rem;
  margin-top: $margin-md;
}