@import "/src/styles";

.description {
  align-self: flex-start;
  position: relative;
  padding-bottom: $padding-xxxs;
  color: var(--gray-6);
  @include font-size-12();

  &.required::before{
    display: inline-block;
    content: "*";
    position: absolute;
    right: -1.8rem;

    color: var(--red);
    font-size: 1.8rem;
    line-height: 1;

    margin-right: 4px;
    font-family: SimSun, sans-serif;
  }
}