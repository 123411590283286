@import "../constants";

.app-select {
  .ant-select-arrow {
    right: 0;
    top: auto !important;
    margin-top: 0;
    margin-right: $icon-margin-right;
    background-color: inherit !important;
    height: $icon-edge-size;
    width: $icon-edge-size;
    border-radius: $border-radius / 2;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: transform .2s ease-in;

    & span {
      width: 100%;
      height: 100%;
      background: url("data:image/svg+xml, %3Csvg width='1.2rem' height='0.9rem' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 6L0.535899 -3.01142e-07L7.4641 -9.06825e-07L4 6Z' fill='%231673CA'/%3E%3C/svg%3E") no-repeat center;
      background-size: 1.2rem;
      svg {
        display: none;
      }
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg);
    }
  }
}