@import "/src/styles";

$resource-types-container-width: 32.8rem;

.container {
  width: $resource-types-container-width;
  border: 1px solid var(--gray-3);
  padding: 3.2rem;
  padding-bottom: $padding-xxs;
  border-radius: 1.2rem;
  overflow: auto;

  & > :last-child {
    border-bottom: none;
  }
}

.container-hidden {
  display: none;
}

.types {
  margin-top: $margin-xxs;
}