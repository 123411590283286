.pagination {
  :global(.ant-pagination-item-active),
  :global(.ant-pagination-item:hover),
  :global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
    border-color: var(--blue);
  }

  :global(.ant-pagination-item:hover a),
  :global(.ant-pagination-item-active a),
  :global(.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon),
  :global(.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon) {
    color: var(--blue);
  }

  :global(.ant-pagination-prev:hover .ant-pagination-item-link),
  :global(.ant-pagination-next:hover .ant-pagination-item-link) {
    color: var(--blue);
    border-color: var(--blue);
  }

  :global(.ant-pagination-disabled:hover .ant-pagination-item-link) {
    color: var(--gray-3);
    border-color: var(--gray-3);
  }

  :global(.ant-pagination-options-size-changer.ant-select) {
    width: 10rem;
  }
}