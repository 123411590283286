@import "./constants";

.ant-picker-cell-inner {
  width: $cell-width !important;
  height: $cell-height !important;
}

.ant-picker-cell .ant-picker-cell-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: $cell-height !important;
  transition: none;
  @include font-size-12;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--dark-blue) !important;
  border-radius: 50% !important;
  width: $cell-width;
  height: $cell-height;
  top: initial;
  right: initial;
  bottom: initial;
  left: initial;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  border-radius: 50%;
  background-color: var(--dark-blue);
  width: $cell-width !important;
  height: $cell-height !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background-color: var(--light-blue);
  border-radius: 50%;
}