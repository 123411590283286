.container {
  width: 100%;
  height: 80vh;
  position: relative;
  transition: all 1;
}

.error {
  font-size: 20rem !important;
  color: var(--blue);
  margin-bottom: 10rem !important;
  animation: 3s linear;
  animation-name: animation;
}

.message {
  font-size: 5rem !important;
  color: var(--blue);
  margin-bottom: 5rem !important;
  animation: 3s linear;
  animation-name: animation;
}

.link {
  font-size: 3rem !important;
  align-self: center;
  animation: 3s linear;
  animation-name: animation;
}

@keyframes animation {
  0%   { opacity: 0}
  100% { opacity: 1}
}
