$margins: 0, 2, 4, 6, 8, 10, 12, 16, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200;

@each $margin in $margins {
  .spacer-#{$margin} {
    display: flex;

    & > * {
      margin-right: #{$margin / 10}rem !important;
    }

    > :last-child {
      margin-right: 0 !important;
    }
  }

  .spacer--vertical-#{$margin} {
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: #{$margin / 10}rem !important;
    }

    > :last-child {
      margin-bottom: 0 !important;
    }
  }
}