@import "/src/styles";

.loader {
  height: 35rem;
}

.container {
  border-radius: 1.2rem;
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
}

.header {
  min-height: 7.1rem;
  padding: 3rem $padding-lg $padding-xs 3.2rem;
  border-bottom: 1px solid var(--border-color);
  color: var(--blue);
  @include font-size-20;
}

.body {
  padding: $padding-lg;

  > :last-child {
    margin-bottom: $margin-xs !important;
  }
}

.submit {
  margin-top: 2.2rem;
}