@import "/src/styles";

.container {
  background-color: var(--bg-color);
  margin-bottom: 6.3rem;
}

.search-button {
  width: 14.7rem;
  @include box-shadow;
}