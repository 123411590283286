@import "/src/styles";

.app-menu {
  border-right: none;
  width: max-content;
  padding: 0;
  border-radius: $border-radius;
  @include box-shadow;
}

