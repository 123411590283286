@import "./constants.module";

@mixin container($bg-color, $br-hover-color, $br-active-color) {
  box-sizing: border-box;
  display: flex;

  padding: 0.2rem $horizontal-padding-borderless;
  height: $ui-element-low-height;
  width: 100%;

  background-color: $bg-color;
  border-bottom: 1px solid $input-borderless-br-color;

  input::placeholder {
    color: $input-placeholder;
  }

  &:hover {
    border-color: $br-hover-color;
  }

  &:focus-within {
    border-color: $br-active-color;
  }
}

@mixin input($bg-color) {
  padding: 0;

  background-color: $bg-color;
  border: none;
  box-shadow: none !important;

  font-size: $input-font-size;
  line-height: $input-line-height;

  color: $input-color;
  caret-color: $input-borderless-br-active-color;
}

.container-borderless {
  @include container($input-bg, $input-borderless-br-hover-color, $input-borderless-br-active-color);

  &--disabled {
    @include container($input-bg-disabled, $input-borderless-br-color, $input-borderless-br-color);
  }
}

.input-borderless {
  @include input($input-bg);

  &--disabled {
    @include input($input-bg-disabled);
    color: $input-color-disabled !important;
  }
}