@import "/src/styles";

$weights: ("thin": 100, "light": 300, "regular": 400, "bold": 700, "black": 900);
$colors: (
  "primary": var(--color-primary),
  "secondary": var(--color-secondary),
  "success": var(--green),
  "warning": var(--yellow),
  "danger": var(--red),
);

.text {
  margin-bottom: 0;

  @each $weight-name, $weight-val in $weights {
    &-weight--#{$weight-name} {
      font-weight: $weight-val;
    }
  }

  @each $color-name, $color-val in $colors {
    &-type--#{$color-name} {
      color: $color-val;
    }
  }

  &-size--12 {
    @include font-size-12;
  }
  &-size--14 {
    @include font-size-14;
  }
  &-size--16 {
    @include font-size-16;
  }
  &-size--18 {
    @include font-size-18;
  }
  &-size--20 {
    @include font-size-20;
  }
  &-size--30 {
    @include font-size-30;
  }
}