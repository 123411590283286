@import "/src/styles";

.title {
  margin-bottom: 0 !important;
}

.title-1 {
  @include font-size-30;
}

.title-2 {
  @include font-size-18;
}

.title-3 {
  @include font-size-16;
}

.title-4 {
  @include font-size-14;
}

.title-5 {
  @include font-size-12;
}