@import "/src/styles";

.tag {
  padding-bottom: $padding-xs;
  border-bottom: 1px solid var(--gray-3);

  &:hover {
    border-bottom-color: var(--blue);

    .text {
      color: var(--blue);
    }
  }
}

.text {
  @include multiline-ellipsis(5);
  font-size: 2rem;
  line-height: 2.8rem;
}