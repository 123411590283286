@import "/src/styles";

.button {
  width: max-content;
  margin-left: 3.2rem;
  margin-top: $margin-xs;

  &.closed {
    margin-left: 0;
  }
}

.opened {
  color: var(--gray-1);
}

.closed {
  color: var(--blue);
}

.icon {
  @include font-size-12;
  font-weight: 500;
}