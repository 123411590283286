@import "src/styles";

.to-basket {
  color: initial;
  cursor: pointer;
  align-items: center;

  &:hover {
    color: var(--blue) !important;
  }

  &--with-text {
    min-width: 21rem;
  }
}

.button {
  svg {
    margin-right: $margin-xxxs;
  }
}