@import "src/styles";

.container {
  padding-top: $padding-lg * 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $margin-xxs;

  cursor: pointer;

  &:hover {
    & .title {
      color: var(--blue) !important;
    }
  }
}

.basket {
  min-width: 5.4rem;
  height: 5.4rem;
  border: 1px solid $gray-1;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;

  &--active {
    background-color: $blue;
    border-color: transparent;
  }
}