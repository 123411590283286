@mixin font($font-family, $font-file, $font-weight) {
  @font-face {
    font-family: $font-family;
    src: url($font-file) format('truetype');
    font-weight: $font-weight;
    font-style: normal;
    font-display: fallback;
  }
}

@include font('Roboto', './Roboto-Thin.ttf', 100);
@include font('Roboto', './Roboto-Light.ttf', 200);
@include font('Roboto', './Roboto-Light.ttf', 300);
@include font('Roboto', './Roboto-Regular.ttf', 400);
@include font('Roboto', './Roboto-Regular.ttf', 500);
@include font('Roboto', './Roboto-Regular.ttf', 600);
@include font('Roboto', './Roboto-Bold.ttf', 700);
@include font('Roboto', './Roboto-Bold.ttf', 800);
@include font('Roboto', './Roboto-Black.ttf', 900);