@import "src/styles";
@import "src/common/input/styles/constants.module";

.textarea {
  width: 100%;

  & textarea {
    resize: none;
    padding: $padding-xxs $horizontal-padding;
    min-height: $ui-element-height * 2;

    background-color: $input-bg;
    border-radius: $border-radius;
    border: 1px solid $input-br-color;
    box-shadow: none !important;

    font-size: $input-font-size;
    line-height: $input-line-height;
    color: $input-color;
    caret-color: $input-br-active-color;

    &::placeholder {
      color: $input-placeholder;
    }

    &:hover {
      border-color: $input-br-hover-color;
    }

    &:focus {
      border-color: $input-br-active-color;
    }
  }
}