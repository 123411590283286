@import "/src/styles";

$button-hover: var(--light-blue);
$button-active: var(--dark-blue);

$button-bg-primary: var(--blue);
$button-color-primary: var(--white);

$button-bg-secondary: var(--white);
$button-color-secondary: var(--gray-2);
$button-br-color-secondary: var(--gray-2);
$button-bg-active-secondary: var(--gray-4);

$button-bg-disabled: var(--white);
$button-color-disabled: var(--gray-1);