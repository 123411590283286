$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1600px;

$layout-max-width: 180rem;
$layout-padding: 1rem 4rem 8rem 5rem;

$header-padding: 2rem 4rem 1rem 5rem;

$border-radius: .3rem;

$margin-xl: 2.6rem;
$margin-lg: 2rem;
$margin-md: 1.6rem;
$margin-sm: 1.4rem;
$margin-xs: 1.2rem;
$margin-xxs: 1rem;
$margin-xxxs: .8rem;

$padding-xl: 2.6rem;
$padding-lg: 2rem;
$padding-md: 1.6rem;
$padding-sm: 1.4rem;
$padding-xs: 1.2rem;
$padding-xxs: 1rem;
$padding-xxxs: .8rem;

$ui-element-height: 4.6rem;
$ui-element-low-height: 2.8rem;