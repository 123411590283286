@import "../styles.module";

@mixin default-colors {
  background-color: $button-bg-primary;
  border-color: $button-bg-primary;
  color: $button-color-primary;
}

.btn-primary {
  @include default-colors;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
    color: $button-color-primary;
  }

  &:focus {
    @include default-colors;
  }

  &:active {
    background-color: $button-active;
    border-color: $button-active;
    color: $button-color-primary;
  }
}