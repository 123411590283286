@import "../../styles/constants.module";

.postfix {
  display: flex;
  align-items: center;

  margin-left: $horizontal-margin;
  height: 100%;

  &--disabled {
    svg {
      cursor: not-allowed;

      path {
        stroke: var(--gray-2);
        fill: var(--gray-2);
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}