@import "/src/styles";

.card {
  border-radius: $border-radius !important;
  transition: all .5s;

  &:hover {
    transform: translateY(-0.3rem);
  }
}

.main {
  width: 100%;
}

.link {
  width: 100%;
}

.description-container {
  width: 100%;
}

.info-container {
  max-width: calc(100% - 20.7rem);
}

.rating {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  margin-top: $margin-xxxs * -0.4;
}

.image {
  width: 8.7rem;
  height: 12.2rem;
  border: 1px solid var(--gray-3);
}

.title {
  font-weight: 700 !important;
  @include multiline-ellipsis(3);
}

.date {
  display: inline;
  @include font-size-18;
}

.content-type {
  @include multiline-ellipsis(2);
}

.description {
  @include multiline-ellipsis(3);
}

.source {
  @include font-size-12;
}

.price-container {
  cursor: initial;
  min-width: 15rem !important;
  padding-left: 4.2rem;
  margin-left: auto;
  height: auto;
  border-left: 1px solid var(--border-color);

  & > div {
    height: 100%;
  }
}

.buy-button {
  align-self: flex-end;

  button {
    padding: 0;
  }
}

.price-text {
  color: var(--blue);
  white-space: nowrap;
  @include font-size-18;
}
