@import "/src/styles";

$explain-height: $padding-lg;

.app-form-item {
  margin-bottom: 0;
  padding-bottom: $explain-height;

  .ant-col.ant-form-item-label {
    padding-bottom: $padding-xxxs;

    label {
      padding-bottom: 0;
      color: var(--gray-6);
      @include font-size-12();
    }
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: inline-block;
    content: "*";
    position: absolute;
    right: -1.8rem;

    color: var(--red);
    font-size: 1.8rem;
    line-height: 0.9;
  }
}

.app-form-item {
  .ant-form-item-explain {
    margin-left: $border-radius / 2;
    min-height: $explain-height;
    height: $explain-height;

    @include font-size-12;

    color: var(--orange);
  }

  &.ant-form-item-has-error {
    .ant-picker,
    .ant-select,
    .app-input {
      border: 1px solid var(--orange);
    }
    .app-input-borderless {
      border-bottom: 1px solid var(--orange);
    }

    .ant-picker:hover,
    .ant-select:hover,
    .app-input:hover {
      border: 1px solid var(--orange);
    }
    .app-input-borderless:hover {
      border-bottom: 1px solid var(--orange);
    }

    .ant-picker:focus,
    .ant-select:focus,
    .app-input:focus {
      border: 1px solid var(--orange);
    }
    .app-input-borderless:focus {
      border-bottom: 1px solid var(--orange);
    }

    .ant-picker:active,
    .ant-select:active,
    .app-input:active {
      border: 1px solid var(--orange);
    }
    .app-input-borderless:active {
      border-bottom: 1px solid var(--orange);
    }
  }

  &.ant-form-item-with-help {
    &:not(.app-form-item--without-explain) {
      padding-bottom: 0;
    }
  }

  &.app-form-item--paddingless {
    padding-bottom: 0;
  }

  &.app-form-item--without-explain {
    .ant-form-item-explain {
      display: none;
    }
  }
}

