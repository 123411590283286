@import "/src/styles";

.header {
  background-color: var(--bg-color);
  display: flex;
  padding-right: 4rem;
}

.spacer {
  width: 100%;
}
