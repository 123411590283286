@import "src/styles";

.container {
  width: 20%;
  padding-top: $margin-lg * 2.5;
}

.button {
  width: 100%;
}

.title {
  border-bottom: 1px solid $gray-4;
  padding-bottom: $padding-sm;
}

.total {
  margin-top: $margin-sm;
}