.container {
  margin-top: 5.3rem;
}

.types {
  margin-top: -5.3rem;
}

.card {
  flex: 1;
  width: auto;
}

.link {
  flex: 1;
}

.content {
  flex: 1;
}