@import "/src/styles";

.image {
  background-position: center center !important;
  background-size: 2.5rem 2.5rem !important;
  background: transparent;
  background-repeat: no-repeat;
}

.preview {
  background-color: var(--gray-5);
  border-radius: $border-radius;
}