@mixin fullSpace {
  background-size: cover !important;
  border: none !important;
}

.preview-BOOK {
  background-image: url("data:image/svg+xml,%3Csvg width='89' height='123' viewBox='0 0 89 123' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3C0 1.34314 1.34315 0 3 0H86C87.6569 0 89 1.34315 89 3V106L71.9715 123H3C1.34315 123 0 121.657 0 120V3Z' fill='%23EEF5FF'/%3E%3Crect x='18' y='49' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='49' width='30' height='2' fill='%2362A6FF'/%3E%3Crect x='63' y='49' width='8' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='57' width='39' height='2' fill='%23D2E5FF'/%3E%3Crect x='23' y='17' width='45' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='25' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='41' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='33' width='48' height='2' fill='%23D2E5FF'/%3E%3Crect x='23' y='71' width='48' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='80' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='88' width='50' height='2' fill='%23D2E5FF'/%3E%3Crect x='46' y='88' width='22' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='98' width='25' height='2' fill='%23D2E5FF'/%3E%3Cpath d='M89 106H72V123L89 106Z' fill='%2362A6FF'/%3E%3Crect x='18' y='98' width='9' height='2' fill='%2362A6FF'/%3E%3C/svg%3E");
  @include fullSpace;
}

.preview-PROGRAM {
  background-image: url("data:image/svg+xml,%3Csvg width='89' height='123' viewBox='0 0 89 123' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3C0 1.34314 1.34315 0 3 0H86C87.6569 0 89 1.34315 89 3V106L71.9715 123H3C1.34315 123 0 121.657 0 120V3Z' fill='%23EEF5FF'/%3E%3Crect x='18' y='47' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='39' y='31' width='32' height='2' fill='%2362A6FF'/%3E%3Crect x='39' y='25' width='14' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='72' width='12' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='80' width='12' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='88' width='12' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='96' width='12' height='2' fill='%2362A6FF'/%3E%3Crect x='55' y='25' width='11' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='39' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='33' y='72' width='38' height='2' fill='%23D2E5FF'/%3E%3Crect x='33' y='80' width='31' height='2' fill='%23D2E5FF'/%3E%3Crect x='33' y='88' width='35' height='2' fill='%23D2E5FF'/%3E%3Crect x='33' y='96' width='25' height='2' fill='%23D2E5FF'/%3E%3Cpath d='M89 106H72V123L89 106Z' fill='%2362A6FF'/%3E%3Ccircle cx='25.5' cy='20.5' r='3.5' stroke='%2362A6FF' stroke-width='2'/%3E%3Cpath d='M32 33C32 29.6863 29.0899 27 25.5 27C21.9101 27 19 29.6863 19 33' stroke='%2362A6FF' stroke-width='2'/%3E%3Crect x='19.5' y='64.5' width='8' height='50' transform='rotate(-90 19.5 64.5)' stroke='%2362A6FF' stroke-width='3'/%3E%3C/svg%3E") !important;
  @include fullSpace;
}

.preview-LECTURE {
  background-image: url("data:image/svg+xml,%3Csvg width='89' height='123' viewBox='0 0 89 123' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3C0 1.3431 1.3431 0 3 0H86C87.657 0 89 1.3431 89 3V120C89 121.657 87.657 123 86 123H3C1.3431 123 0 121.657 0 120V3Z' fill='%23EEF5FF'/%3E%3Cpath d='M56 99V76M22 76V99V76Z' stroke='%2362A6FF' stroke-width='3'/%3E%3Cpath d='M39 47.5C42.5899 47.5 45.5 44.5899 45.5 41C45.5 37.4101 42.5899 34.5 39 34.5C35.4101 34.5 32.5 37.4101 32.5 41C32.5 44.5899 35.4101 47.5 39 47.5Z' fill='%23EEF5FF' stroke='%2362A6FF' stroke-width='3'/%3E%3Cpath d='M51 64C51 57.3726 45.6274 52 39 52C32.3726 52 27 57.3726 27 64' stroke='%2362A6FF' stroke-width='3'/%3E%3Cpath d='M58.5 65.5H19.5V75.5H58.5V65.5Z' stroke='%2362A6FF' stroke-width='3'/%3E%3Cpath d='M22 67V54.6056C22 53.6025 22.5013 52.6658 23.3359 52.1094L25 51' stroke='%2362A6FF' stroke-width='2'/%3E%3Cpath d='M56 67V54.6056C56 53.6025 55.4987 52.6658 54.6641 52.1094L53 51' stroke='%2362A6FF' stroke-width='2'/%3E%3Cpath d='M26.5 52C27.8807 52 29 50.8807 29 49.5C29 48.1193 27.8807 47 26.5 47C25.1193 47 24 48.1193 24 49.5C24 50.8807 25.1193 52 26.5 52Z' stroke='%2362A6FF' stroke-width='2'/%3E%3Cpath d='M51.5 52C50.1193 52 49 50.8807 49 49.5C49 48.1193 50.1193 47 51.5 47C52.8807 47 54 48.1193 54 49.5C54 50.8807 52.8807 52 51.5 52Z' stroke='%2362A6FF' stroke-width='2'/%3E%3Cpath d='M71 16H55V18H71V16Z' fill='%23D2E5FF'/%3E%3Cpath d='M34 16H18V18H34V16Z' fill='%2362A6FF'/%3E%3Cpath d='M25 21H18V23H25V21Z' fill='%2362A6FF'/%3E%3Cpath d='M71 31H62V33H71V31Z' fill='%23D2E5FF'/%3E%3Cpath d='M51 28V21H49V28H51Z' fill='%23D2E5FF'/%3E%3Cpath d='M71 28V21H69V28H71Z' fill='%23D2E5FF'/%3E%3Cpath d='M71 21H49V23H71V21Z' fill='%23D2E5FF'/%3E%3Cpath d='M71 26H51V28H71V26Z' fill='%23D2E5FF'/%3E%3Cpath d='M71 96H21V99H71V96Z' fill='%2362A6FF'/%3E%3Cpath d='M71 99V52H68V99H71Z' fill='%2362A6FF'/%3E%3C/svg%3E") !important;
  @include fullSpace;
}

.preview-ART {
  background-image: url("data:image/svg+xml,%3Csvg width='89' height='123' viewBox='0 0 89 123' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3C0 1.34314 1.34315 0 3 0H86C87.6569 0 89 1.34315 89 3V106L71.9715 123H3C1.34315 123 0 121.657 0 120V3Z' fill='%23EEF5FF'/%3E%3Crect x='19.5' y='44.5' width='18' height='24' stroke='%2362A6FF' stroke-width='3'/%3E%3Crect x='46' y='43' width='25' height='2' fill='%2362A6FF'/%3E%3Crect x='46' y='51' width='25' height='2' fill='%2362A6FF'/%3E%3Crect x='46' y='59' width='25' height='2' fill='%2362A6FF'/%3E%3Crect x='46' y='68' width='25' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='20' width='53' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='27' width='29' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='77' width='53' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='85' width='42' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='34' width='53' height='2' fill='%2362A6FF' fill-opacity='0.2'/%3E%3Crect x='18' y='94' width='52.4255' height='2' fill='%2362A6FF' fill-opacity='0.2'/%3E%3Cpath d='M89 106H72V123L89 106Z' fill='%2362A6FF'/%3E%3C/svg%3E") !important;
  @include fullSpace;
}

.preview-GUIDE1 {
  background-image: url("data:image/svg+xml,%3Csvg width='89' height='123' viewBox='0 0 89 123' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3C0 1.34314 1.34315 0 3 0H86C87.6569 0 89 1.34315 89 3V106L71.9715 123H3C1.34315 123 0 121.657 0 120V3Z' fill='%23EEF5FF'/%3E%3Crect x='18' y='16' width='25' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='24' width='30' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='32' width='46' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='65' width='47' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='73' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='81' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='89' width='49' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='97' width='30' height='2' fill='%23D2E5FF'/%3E%3Cpath d='M89 106H72V123L89 106Z' fill='%2362A6FF'/%3E%3Cpath d='M25.8276 55.9732H21.1559L20.1785 59H18L22.5557 46H24.436L29 59H26.8133L25.8276 55.9732ZM21.744 54.1518H25.2395L23.4917 48.7589L21.744 54.1518Z' fill='%2362A6FF'/%3E%3C/svg%3E") !important;
  @include fullSpace;
}

.preview-TUTOR {
  background-image: url("data:image/svg+xml,%3Csvg width='89' height='123' viewBox='0 0 89 123' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3C0 1.34314 1.34315 0 3 0H86C87.6569 0 89 1.34315 89 3V106L71.9715 123H3C1.34315 123 0 121.657 0 120V3Z' fill='%23EEF5FF'/%3E%3Crect x='18' y='17' width='53' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='41' width='19' height='2' fill='%2362A6FF'/%3E%3Crect x='18' y='63' width='13' height='2' fill='%2362A6FF'/%3E%3Crect x='49' y='68' width='17' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='68' width='22' height='2' fill='%2362A6FF'/%3E%3Crect x='49' y='63' width='22' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='46' width='38' height='2' fill='%2362A6FF'/%3E%3Cpath d='M89 106H72V123L89 106Z' fill='%2362A6FF'/%3E%3Crect x='19' y='35' width='12' height='29' transform='rotate(-90 19 35)' stroke='%23D2E5FF' stroke-width='2'/%3E%3Crect x='54' y='35' width='12' height='16' transform='rotate(-90 54 35)' stroke='%23D2E5FF' stroke-width='2'/%3E%3Crect x='19' y='57' width='6' height='51' transform='rotate(-90 19 57)' stroke='%23D2E5FF' stroke-width='2'/%3E%3Crect x='19' y='99' width='25' height='20' transform='rotate(-90 19 99)' stroke='%23D2E5FF' stroke-width='2'/%3E%3Crect x='50' y='95' width='21' height='20' transform='rotate(-90 50 95)' stroke='%2362A6FF' stroke-width='2'/%3E%3C/svg%3E") !important;
  @include fullSpace;
}

.preview-GUIDE3 {
  background-image: url("data:image/svg+xml,%3Csvg width='89' height='123' viewBox='0 0 89 123' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3C0 1.34314 1.34315 0 3 0H86C87.6569 0 89 1.34315 89 3V106L71.9715 123H3C1.34315 123 0 121.657 0 120V3Z' fill='%23EEF5FF'/%3E%3Crect x='18' y='47' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='39' width='53' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='72' width='25' height='2' fill='%23D2E5FF'/%3E%3Crect x='46' y='72' width='25' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='80' width='25' height='2' fill='%23D2E5FF'/%3E%3Crect x='46' y='80' width='25' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='88' width='25' height='2' fill='%23D2E5FF'/%3E%3Crect x='46' y='88' width='25' height='2' fill='%23D2E5FF'/%3E%3Crect x='18' y='96' width='25' height='2' fill='%23D2E5FF'/%3E%3Crect x='46' y='96' width='15' height='2' fill='%23D2E5FF'/%3E%3Cpath d='M89 106H72V123L89 106Z' fill='%2362A6FF'/%3E%3Crect x='19.5' y='64.5' width='8' height='22' transform='rotate(-90 19.5 64.5)' stroke='%2362A6FF' stroke-width='3'/%3E%3Crect x='47.5' y='64.5' width='8' height='22' transform='rotate(-90 47.5 64.5)' stroke='%2362A6FF' stroke-width='3'/%3E%3Crect x='31.5' y='32.5' width='15' height='38' transform='rotate(-90 31.5 32.5)' stroke='%2362A6FF' stroke-width='3'/%3E%3C/svg%3E") !important;
  @include fullSpace;
}
