@import "/src/styles";

.year {
  width: 7.2rem;
  height: 2rem;
  border: none !important;
  padding: 0 !important;
  background-color: inherit !important;

  &:hover {
    border: none;
    border-bottom: 1px solid var(--blue);
  }

  :global(.ant-picker-input) {
    border-bottom: 1px solid var(--blue);
    border-radius: 0;

    &:hover {
      border-bottom: 1px solid var(--light-blue);
    }

    input {
      padding-left: $padding-lg;
    }
  }

  :global(.ant-picker-suffix) {
    display: none;
  }

  :global(.ant-picker-clear) {
    width: 2rem;
    height: 2rem;
  }
}