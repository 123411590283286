.loader {
  border: 3px solid transparent;
  border-top-color: var(--blue);
  border-bottom-color: var(--blue);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  z-index: 1000000;
  animation: 1.5s infinite linear;
  animation-name: spin;
}

.medium {
  width: 3rem;
  height: 3rem;
}

.small {
  width: 1.5rem;
  height: 1.5rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
