@import "../constants/colors";

:root {
  --dark-blue: #{$dark-blue};
  --blue: #{$blue};
  --light-blue: #{$light-blue};
  --green: #{$green};
  --yellow: #{$yellow};
  --orange: #{$orange};
  --red: #{$red};
  --black: #{$black};
  --gray-1: #{$gray-1};
  --gray-2: #{$gray-2};
  --gray-3: #{$gray-3};
  --gray-4: #{$gray-4};
  --gray-5: #{$gray-5};
  --gray-6: #{$gray-6};
  --gray-7: #{$gray-7};
  --white: #{$white};

  --color-primary: var(--black);
  --color-secondary: var(--gray-1);
  --bg-color: var(--gray-5);
  --border-color: var(--gray-3);
}