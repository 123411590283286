@import "../styles.module";

@mixin transparent {
  background: none !important;
  border: none !important;
}

.btn-text {
  padding: 0;
  height: auto;
  @include transparent;
  color: $button-bg-primary;

  &:hover {
    @include transparent;
    color: $button-hover;
  }

  &:focus {
    @include transparent;
    color: $button-bg-primary;
  }

  &:active {
    @include transparent;
    color: $button-active;
  }

  &[ant-click-animating-without-extra-node=true]:after {
    display: none !important;
  }
}