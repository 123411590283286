@import "./constants";
@import "./components/item";
@import "./components/input";
@import "./components/dropwdown";
@import "./components/placeholder";
@import "./icons/select-arrow";
@import "./icons/clear";
@import "./multiple";

.app-select.ant-select {
  display: flex;
  align-items: center;

  height: $ui-element-height;
  width: 100%;

  border: 1px solid $input-br-color;
  border-radius: $border-radius;
  background-color: $input-bg;

  &:hover {
    border-color: $input-br-hover-color;
  }

  &:focus-within {
    border-color: $input-br-active-color;
  }

  .ant-select-selector {
    border-radius: inherit;
    border: none;
    box-shadow: none !important;
    line-height: $input-line-height;
    background-color: inherit;

    &::after {
      line-height: $input-line-height !important;
    }
  }

  .ant-select-selector,
  .ant-select-selection-search {
    left: 0;

    padding: $padding-xxs $horizontal-padding;
    padding-right: $horizontal-padding + $icon-edge-size;
    height: 100%;
    width: 100%;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item,
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
}