@import "/src/styles";
@import "./styles.module";

@mixin default-colors {
  background-color: $button-bg-secondary;
  border-color: $button-br-color-secondary;
  color: $button-color-secondary;
}

.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $padding-md / 2 $padding-lg;
  height: $ui-element-height;
  border-radius: $border-radius;
  box-shadow: none;
  text-shadow: none;

  @include font-size-14;
  @include default-colors;

  &:hover {
    background-color: $button-bg-secondary;
    border-color: $button-hover;
    color: $button-hover;
  }

  &:focus {
    @include default-colors;
  }

  &:active {
    background-color: $button-bg-active-secondary;
    border-color: $button-active;
    color: $button-active;
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:active {
  background-color: $button-bg-disabled;
  border-color: $button-color-disabled;
  color: $button-color-disabled;
}