@import "../../../styles/constants/index";
@import "/src/app/components/search/content-filters-list/styles.module";

$home-content-container-width: calc(100% - #{$content-types-container-width} - 2rem);

.container {
  width: $home-content-container-width;
  min-width: 35rem;
}

.title {
  margin-bottom: $margin-sm !important;
}